@charset "utf-8";

$text-color: #f4c667;
$link-color: #bbbbff;
$link-color-hover: #f4c667;
$background-color: #177076;
$primary-color: #0e0f39;
$tertiary-color: #5d5b86;

html {
  font-size: 100%;
  font-family: 'Lato', sans-serif;
  line-height: 1.5;
  background-color: $background-color;
}

body {
  color: $text-color;
  background-color: $background-color;
  padding-bottom: 2rem;
}

// ---
// Headings and typography
// ---

.sale {
  font-weight: bold;
  color: #ff5852;
  text-shadow: 1px 1px 2px #000;
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.1rem;
}

a {
  color: $link-color;
  text-decoration: none;
}

logo a img {
  opacity: 1;
  transition-duration: 0.1;
  transition-property: opacity;
  transition-timing-function: ease;
}

logo a:hover img,
logo a:focus img {
  opacity: 0.7;
  transition-duration: 0.2;
  transition-property: opacity;
  transition-timing-function: ease;
}

a:focus,
a:hover {
  color: $link-color;
}

.content a:focus,
.content a:hover {
  color: $link-color-hover;
  text-decoration: none;
}

img {
  display: inline-block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}

table {
  width: 100%;
  text-align: left;
  border-spacing: 0;
}

table th {
  color: $text-color;
  background-color: $primary-color;
}

table td {
  background-color: $tertiary-color;
}


table th,
table td {
  padding: 0.3em;
  vertical-align: top;
  border-right: 1px solid $text-color;
  border-bottom: 1px solid $text-color;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 1rem;
}
table thead th:last-child {
  border-top-right-radius: 1rem;
  border-right: 1px solid $text-color;
}
table thead th:first-child {
  border-top-left-radius: 1rem;
  border-left: 1px solid $text-color;
  padding-left: 0.5em;
}
table thead th {
  border-top: 1px solid $text-color;
}

table tbody tr:last-child th,
table tr:last-child td:first-child {
  border-bottom-left-radius: 1rem;
  border-left: 1px solid $text-color;
}
table tbody th,
table td:first-child {
  padding-left: 0.5em;
  border-left: 1px solid $text-color;
}
table tr:first-child {
  border-top: 1px solid $text-color;
}

.submitform {
width: 80%;
margin-left: 10%;
margin-right: 10%;
}

.submitform td {
  text-align: right;
  background-color: $primary-color;
}

.submitform th {
  width: 25%;
}

.submitform textarea,
.submitform input[type=text] {
  width: calc(100% - 0.5em);
  padding: 0.2em;
  background-color: $tertiary-color;
  color: $text-color;
  border-width: 1px;
  border-radius: 0.5rem;
  border-style: none;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}

.submitform input[type=text] {
  font-weight: bold;
}

.submitform input[type=submit],
.submitform select {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  width: calc(100% - 0.1em);
  font-weight: bold;
  background-color: $text-color;
  border-width: 1px;
  border-radius: 0.4rem;
  border-style: solid;
  border-color: $primary-color;
}
.submitform td:last-child {
  text-align: center;
}
.submitform input[type=submit] {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
    width: 50%;
}

.submitform input[type=submit]:hover {
  background-color: $primary-color;
  color: $text-color;
  border-color: $text-color;
  border-width: 1px;
  border-style: solid;
}

.pricing th {
  width: 30%;
}
.pricing td {
  width: 20%;
}

.smallcaps {
  font-weight: bold;
 font-variant: small-caps;
}

.bold {
  font-weight: 800;
}

small {
  font-size: 90%;
  font-weight: normal;
}

// ---
// Containers
// ---

.masthead {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $primary-color;
}

.container {
  width: 90%;
  max-width: 960px;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 90%;
  max-width: 960px;
  padding-top: 0.5rem;
  text-align: center;
  font-size: 1.5rem;
}

.logo {
  display: inline-block;
  width: 100%;
  max-width: 96px;
  height: auto;
  flex: 0 auto;
  margin-right: 1rem;
}

// ---
// Columns
// ---

.columns {
  display: flex;
  flex-direction: column;
}

.column {
  flex: 1;
  background-color: $primary-color;
  margin-top: 1em;
}

.column.empty {
  background-color: transparent;
}

.columns .titlebar {
  font-size: 0.8rem;
  margin: 0;
  padding-left: 0.3em;
  padding-right: 0.5em;
  position: relative;
  top: -0.2em;
}

.grid-item .titlebar {
	overflow: hidden;
  font-size: 0.7rem;
  margin: 0;
  padding-left: 0.3em;
  padding-right: 0.5em;
  position: relative;
  top: -17px;
}

.grid-item .titlebar a,
.column .titlebar a {
  float: right;
}

a {
  display: inline-block;
  position: relative;
}

a .link::after {
  content: "";
  width: 0%;
  background-color: $link-color-hover;
  height: 1px;
  display: block;
  position: relative;
  top: -0.2em;
  transition: width;
  transition-duration: 0.1s;
  transition-timing-function: ease;
}

a:focus .link::after,
a:hover .link::after {
  width: 100%;
  transition: width;
  transition-duration: 0.18s;
  transition-timing-function: ease;
}

@media screen and (min-width: 700px) {
  .columns {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .column {
    margin-right: 1rem;
    min-width: calc(33% - 1rem);
  }
}

.preview {
  width: 100%;
  border: 0;
}


.column .preview {
  height: 28rem;
}

img.preview {
  // default
  object-fit: cover;
}
img.preview--contain {
  object-fit: contain;
}

img.preview--cover {
  object-fit: cover;
}

img.preview--bottom {
  object-position: 50% 100%;
}
img.preview--top {
  object-position: 50% 0%;
}
/* ---- grid ---- */

.grid {
  border: 0px;
}

.grid-item {
  position: relative;
  float: left;
  width: 200px;
  height: 200px;
  margin: 4px;
  background-color: $primary-color;
  color: $text-color;
  font-size: 50px;
}

img.preview {
  object-fit: cover;
}

img.preview--contain {
  object-fit: contain;
}

img.preview--cover {
  object-fit: cover;
}

img.preview--bottom {
  object-position: 50% 100%;
}

img.preview--top {
  object-position: 50% 0%;
}
.grid-item .preview {
  height: 172px;
}
.grid-item--width2 { width: 408px; }
.grid-item--width3 { width: 616px; }
.grid-item--height2 { height: 408px; }
.grid-item--height3 { height: 616px; }

.grid-item--height2 .preview { height: 378px; }
.grid-item--height3 .preview { height: 682px; }

// ---
// Gallery Filter
// ---

#filters {
	margin-top: 5px;
	margin-left: 5px;
}

#filters button {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  color: $text-color;
  background-color: $primary-color;
  border-width: 1px;
  border-radius: 0.4rem;
  font-weight: bold;
  font-size: 0.8rem;
  border-style: solid;
  border-color: $text-color;
}

#filters button:hover,
#filters button.is-checked {
  background-color: $text-color;
  color: $primary-color;
  border-color: $primary-color;
  border-width: 1px;
  border-style: solid;
}

// ---
// Navigation
// ---

.navigation {
  font-size: 1.2rem;
  flex: 1;
  align-self: center;
}

.navigation ul {
  float: right;
  direction: rtl;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-right: 20px;
}

.navigation li {
  flex: 1;
  text-align: right;
  float: left;
  margin-left: 2rem;
}

.navigation li a {
  display: block;
  transition: all 0.1s ease-in-out;
  color: $link-color;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1rem;
}

.navigation li a:focus,
.navigation li a:hover {
  text-decoration: none;
  color: $link-color-hover;
}

@media screen and (min-width: 700px) {

  .navigation ul {
    float: right;
    direction: rtl;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-right: 20px;
  }

  .navigation li {
    flex: 1;
    text-align: right;
    float: left;
    margin-left: 2rem;
  }
  .grid-item {
  	width: 232px;
  	height: 232px;
  }
  .grid-item .preview {
	  height: 202px;
  }
  .grid-item .titlebar {
  	font-size: 0.9rem;
  	top: -14px;
  }
  .grid-item--width2 { width: 472px; }
  .grid-item--width3  { width: 712px; }
	.grid-item--height2 { height: 472px }
	.grid-item--height3 { height: 712px;  }

	.grid-item--height2 .preview { height: 442px; }
	.grid-item--height3 .preview { height: 682px; }
}

// ---
// Media queries
// ---

@media screen and (min-width: 700px) {
  html {
    font-size: 100%;
  }
}

@media screen and (min-width: 1000px) {
  html {
    font-size: 100%;
  }
}
@media screen and (max-width: 461px) {
	.grid-item {
  	width: 100%;
  	height: 422px;
  }
	.grid-item .preview { height: 402px; }
	.grid-item .titlebar { top: -18px; }
  .grid-item--width2 { width: 100%; }
  .grid-item--width3 { width: 100%; }
	.grid-item--height2 { height: 422px; }
	.grid-item--height3 { height: 422px; }

	.grid-item--height2 .preview { height: 402px; }
	.grid-item--height3 .preview { height: 402px; }

  img.preview {
    object-fit: cover;
    object-position: center 10%;
  }
  video.preview {
    object-fit: cover;
    object-position: center 10%;
  }
}
